<template>
  <div :style="{transform:`translateY(${tableTop}px)`,transition}" class="carport-state fs11"
       @mouseout="moutRoll" @mouseover="moverRoll"
       @transitionend="onTransitionEnd">
    <!-- <div v-for="(item,index) in listData.length>listSize? listData.concat(listData):listData" :key="item.id" >
      <div  class="carport-state-item" :class="[item.status == 1 ? 'item-background-in' :'item-background-out']">

        <p @click="gotoPg(item.id)" class="ttp" style="cursor: pointer;" :title="item.title">{{item.title.substr(0,15)+'...'}}<i style="float:right;margin-top: 2px;">{{item.createTime.substr(0,10)}}</i></p>

      </div>
    </div> -->
    <!-- 这里再放一个和上面一模一样的dom是为了实现无缝滚动的效果 -->
    <!-- <div v-for="(item,index) in listData" :key="item.id" v-show="isAutoRoll">
      <div class="carport-state-item" :class="[item.status == 1 ? 'item-background-in' :'item-background-out']">

        <p @click="gotoPg(item.id)" class="ttp" style="cursor: pointer;" :title="item.title">{{item.title.substr(0,10)+'...'}}      <i style="float:right;margin-top: 2px;">{{item.createTime.substr(0,10)}}</i></p>

      </div>
    </div> -->

    <div v-for="(li,index) in listData.length>listSize? listData.concat(listData):listData" :key="index"
         class="news-title">
      <div class=" flex">
        <div class="flex-1">
          <div class="title  text-overflow" @click="gotoPg(li)">
            <i class="el-icon-document" style="margin-right: 5px"></i>
            <span :title="li.title" class=" ">{{ li.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RollList',
  props: {
    listData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      tableTimer: null,
      isAutoRoll: false, // 是否开启滚动
      tableTop: 0, // 滚动的像素
      listSize: 7, //容器内可视最大完整行数
      ListTimerInterval: 1500, //向上滚动 1px 所需要的时间，越小越快 100
      //
      nowCount: 0,
      //
      nowNumber: 0,//记录滚动个数，默认滚动数据条数
      hanggao: 37,//每行记录的高度
      transitionTimerInterval: 500, //过渡动画时间间隔ms
    };
  },
  computed: {
    // 根据不同分辨率动态获取滚动元素item的高度（包含margin-top/bottom,border等）
    lineHeight() {
      const height = document.getElementsByClassName("carport-state-item")[0].clientHeight;
      return height;
    },
    transition() {
      if (this.tableTop === 0) {
        return 'transform 0ms ease-in-out';
      } else {
        return 'transform ' + this.transitionTimerInterval + 'ms ease-in-out';
      }
    },
  },

  watch: {
    // 侦听数据的改变，是否判断要进行滚动
    listData: {
      handler(newValue, oldValue) {
        if (newValue.length > this.listSize && !this.isAutoRoll) {
          this.isAutoRoll = true;
        }
        if (newValue.length <= this.listSize) {
          this.isAutoRoll = false;
        }
      },
      deep: true,
      immediate: true,
    },
    isAutoRoll: {
      handler(newValue) {
        if (newValue) {
          this.tableTimerFun();
        } else {
          this.tableTop = 0;
          clearInterval(this.tableTimer);
        }
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.tableTop = 0;
    this.isAutoRoll = false;
    clearInterval(this.tableTimer);
  },
  methods: {
    gotoPg(idP) {
      console.log('详情', idP);
      this.$emit('gotoPg', idP)
    },
    moverRoll() {
      if (this.listData.length <= this.listSize) {
        return;
      }
      // console.log('移入',Math.random());
      clearInterval(this.tableTimer);
      this.tableTimer = null;
    },
    moutRoll() {
      if (this.listData.length <= this.listSize) {
        return;
      }
      // console.log('移出',Math.random());
      this.tableTimerFun();
    },
    // 无缝滚动效果的实现
    tableTimerFun() {

      this.tableTimer = setInterval(() => {
        // if (this.nowCount < this.listData.length * this.lineHeight*2) {
        if (this.nowNumber < this.listData.length + 1) {
          // this.tableTop -= 1;
          // this.nowCount++;
          this.tableTop -= this.hanggao;
          this.nowCount += this.hanggao;
          //
          this.nowNumber += 1;
          console.log('移动', this.nowNumber);
        } else {
          // this.nowCount = 0;
          // this.tableTop = 0;
        }
      }, this.ListTimerInterval);
      // let count = 0;
      // this.tableTimer = setInterval(() => {
      //   if (count < this.listData.length * this.lineHeight) {
      //     this.tableTop -= 1;
      //     count++;
      //   } else {
      //     count = 0;
      //     this.tableTop = 0;
      //   }
      // }, this.ListTimerInterval);
    },
    onTransitionEnd() {
      if (this.nowNumber >= this.listData.length) {
        this.nowCount = 0;
        this.tableTop = 0;
        this.nowNumber = 0;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// /deep/.el-progress__text{
//   font-size:16px !important;
//   font-weight: bolder;
// }
.fs12 {
  font-size: 12px;
  // padding:0 20px;
}

.fs11 {
  font-size: 11px;
  line-height: 18px;
}

.ttp:hover {
  // color:#0a28b4;
  font-weight: bold;
  border: 1px dotted white;
  // font-size:16px;
}


.news-title {
  border-bottom: 1px dashed rgba(255, 255, 255, .3);
  padding-left: 5px;
  cursor: pointer;

  .title {
    width: 350px;
    // padding:8px 0;
    padding: 9px 0;
  }

  &:hover {
    color: #0079E9;
  }
}

.carport-state {
  // width: 354px;
  // height: 280px;
  // background-color: red;
  // background: rgba(255,255,255,0.5);
  // border-radius: 8px;
  // padding: 20px;
  // margin-left: 8px;
  color: #333;
  // overflow: hidden;
  font-size: 14px;
}

</style>
