<template>
    <div class="p-index bg">
      <div style="text-align: center">
        <video  ref="videos"  autoplay muted loop class="bg_video">
          <source :src="src" type="video/mp4"/>
        </video>
      </div>
      <div class="bgall">
        <div class="tops">
          <p class="title">
            <logo></logo>
          </p>
          <p class="title1">
            <logo1></logo1>
          </p>
          <p>企业服务，招商引资，助力企业数字化业务增长</p>
          <el-input placeholder="请输入内容" v-model="keyword" class="input-with-select">
            <el-select style="width: 120px" v-model="selects" slot="prepend" placeholder="请选择">
              <el-option label="项目申报" value="xmsb"></el-option>
              <el-option label="政策汇集" value="zchj"></el-option>
              <el-option label="政策解答" value="zcjd"></el-option>
            </el-select>
            <el-button slot="append" icon="el-icon-search" class="btn"  @click="gotosearch()"></el-button>
          </el-input>
          <div class="remen">热门搜索： 项目申报 政策资讯 在线展厅</div>
        </div>
        <div class="content">
          <div class="flex">
            <div class="left1"  @click="gotoindex('')">
              <div class="imgbg"></div>
              <div class="ltitle">黄骅展厅</div>
              <div>新经济、新战略，推进招商引资，促进经济大发展</div>
              <div class="chakan">查看详情<i class="el-icon-arrow-right"></i></div>
            </div>
            <div class="left2">
              <div class="flex">
                <div class="top1" @click="gotoindex('/zchj')">
                  <div class="image">  <el-image :src="top1"></el-image></div>
                  <div class="fontw">产业政策</div>
                  <div class="font14">优化产业结构，推动产业融合，实现产业发展新突破</div>
                </div>
                <div class="top1" @click="gotoindex('/parkindex')">
                  <div class="image">  <el-image :src="top2"></el-image></div>
                  <div class="fontw">园区管理</div>
                  <div class="font14">创新高地,生态园区,活力经开创新“区”动,繁昌“园”梦</div>
                </div>
              </div>
              <div class="flex">
                <div class="top2" @click="gotoindex('/gxjy')">
                  <div class="image">  <el-image :src="top1"></el-image></div>
                  <div class="fontw">供需交易</div>
                  <div class="font14">筑供需桥梁 塑诚信平台</div>
                </div>
                <div class="top2" @click="gotoindex('/compnumber')">
                  <div class="image">  <el-image :src="top2"></el-image></div>
                  <div class="fontw">智能制造</div>
                  <div class="font14">数字赋能 智领未来</div>
                </div>
                <div class="top2" @click="gotoindex('/logistics')">
                  <div class="image">  <el-image :src="top1"></el-image></div>
                  <div class="fontw">物流服务</div>
                  <div class="font14">速度领先 畅享无忧</div>
                </div>
              </div>
            </div>
            <div class="left3">
              <div class="newsbox">
                <div><span class="zxbg">全国招标信息</span><span class="seeMore" @click="gotozc">查看更多<i class="el-icon-arrow-right"></i></span></div>
                <!-- <el-carousel height="320px"  direction="vertical" indicator-position="none" :autoplay="true">
                  <el-carousel-item  :key="1">
                    <div class="news-title" v-for="(li,index) in ul.slice(0,7)" :key="index" @click="gotozcxq(li)">
                      <div class=" flex">
                        <div class="flex-1">
                          <div class="title  text-overflow"><i class="el-icon-document" style="margin-right: 5px"></i><span class=" " :title="li.title">{{li.title}}</span></div>
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item :key="2">
                    <div class="news-title" v-for="(li,index) in ul.slice(7,13)" :key="index" @click="gotozcxq(li)">
                      <div class=" flex">
                        <div class="flex-1">
                          <div class="title  text-overflow"><i class="el-icon-document" style="margin-right: 5px"></i><span class=" " :title="li.title">{{li.title}}</span></div>
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel> -->
                <div style="overflow: hidden;">
                  <RollNewList :listData="ul" @gotoPg="gotozcxq"></RollNewList>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import bottom from "../../components/bottom";
import headers from "../../components/headers";
import {formatFun} from "../../utils/index"
import logo from "../../components/logo.vue"
import logo1 from "../../components/logo1.vue"
import RollNewList from "../../components/RollNewList.vue"

export default {
  name: "home",
  data() {
    return {
      top1:require('../../assets/home/top1.png'),
      top2:require('../../assets/home/top2.png'),
      src:require('../../assets/banner/bg.mp4'),
      selects:'xmsb',
      keyword:'',
      lists:[{title:'产业政策', src:'zchj',alias:'cyzc'},{title:'物流仓储', src:'wlfw'},{title:'园区管理', src:'yqgl'},{title:'项目申报', src:'xmsb'},{title:'专家咨询', src:'zjzx'},
        {title:'在线培训', src:'zxpx'},{title:'融资服务', src:'jrfw'},{title:'人力资源', src:'rlzy'},{title:'智能制造', src:'znzz'},{title:'中介服务', src:'jdpg'},{title:'咨询服务', src:'zxfw'}],
      ul:[],
      formatFun:formatFun
    };
  },
  components:{
    bottom,headers,logo,logo1,RollNewList
  },
  computed: {},
  created() {
    this.$api.index.biddinglist({
      current:1,
      size:14
    }).then((res)=>{
        this.ul=res.data.records
    })
  },
  mounted() {
    setTimeout(() => {
      let video = this.$refs.videos
      video.src = require('../../assets/banner/bg1.mp4')
    }, 18000)
  },
  methods: {
    gotozc(){
      this.$router.push({
        path:'/allzhaobiao'
      })
    },
    gotoindex(key){
      this.$router.push({
        path:key
      })
    },
    gotozcxq(li){
      console.log('过来了',li);
      this.$router.push({
        path: '/allzhaobiaoxq',
        query: {
          id: li.id,
        }
      })
    },
    gotosearch(){
      if(this.selects){
        this.$router.push({
          path:`/${this.selects}`,
          query:{
            keyword:this.keyword
          }
        })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-input-group__prepend{
  background-color: #fff!important;
}
.remen{
  text-align: left;
  margin-left: 76px;
  margin-top: 15px;
  color: #eeeeee;
}
.top1{
  width: 260px;
  height: 193px;
  border-radius: 8px;
  background: linear-gradient(45deg, #0B83F1, #1C58CD);
  margin-left: 8px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  .image{
    width: 80px;
    height: 80px;
    margin: 15px auto 10px auto;
  }
  &:hover {
    background: linear-gradient(45deg,#35B1F5,#1C73DA);
  }

  .fontw{
    font-size: 16px;
    font-weight: bolder;
    line-height: 35px;
  }
  .font12{
    font-size: 14px;
    padding: 0 20px;
  }
}
.top2{
  width: 172px;
  height: 119px;
  cursor: pointer;
  border-radius: 8px;
  background: linear-gradient(45deg, #0B83F1, #1C58CD);
  margin-left: 8px;
  margin-top: 8px;
  color: #fff;
  text-align: center;
  .image{
    width: 50px;
    height: 50px;
    margin: 6px auto 5px auto;
  }
  .fontw{
    font-size: 16px;
    font-weight: bolder;
    line-height: 26px;
  }
  .font12{
    font-size: 14px;
    padding: 0 10px;
  }
  &:hover {
    background: linear-gradient(45deg,#35B1F5,#1C73DA);
  }
}
.ulists{
  width:660px;
  height: 245px;
  background: #006FF0;
  text-align: center;
  .itemli{
    width: 110px;
    height: 115px;
    cursor: pointer;
    border-radius: 8px;
  }
  .itemli:hover{
    background: rgb(7,68,151);
  }
  .itemicon{
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 auto;

  }
  .cyzc{
    background: url("../../assets/icons/cyzc.png") no-repeat;
    background-size: 100% 100%;
  }
  .wlfw{
    background: url("../../assets/icons/wlfw.png") no-repeat;
    background-size: 100% 100%;
  }
  .yqgl{
    background: url("../../assets/icons/yqgl.png") no-repeat;
    background-size: 100% 100%;
  }
  .xmsb{
    background: url("../../assets/icons/xmsb.png") no-repeat;
    background-size: 100% 100%;
  }
  .zjzx{
    background: url("../../assets/icons/zjzx.png") no-repeat;
    background-size: 100% 100%;
  }
  .zxpx{
    background: url("../../assets/icons/zxpx.png") no-repeat;
    background-size: 100% 100%;
  }
  .jrfw{
    background: url("../../assets/icons/jrfw.png") no-repeat;
    background-size: 100% 100%;
  }
  .rlzy{
    background: url("../../assets/icons/rlzy.png") no-repeat;
    background-size: 100% 100%;
  }
  .znzz{
    background: url("../../assets/icons/znzz.png") no-repeat;
    background-size: 100% 100%;
  }
  .jdpg{
    background: url("../../assets/icons/jdpg.png") no-repeat;
    background-size: 100% 100%;
  }
  .zxfw{
    background: url("../../assets/icons/zxfw.png") no-repeat;
    background-size: 100% 100%;
  }
  .itemtitle{
    margin-top: 15px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 18px;
  }
}
.qiye{
  background: url("../../assets/icons/qiye.png") no-repeat;
  background-size: 100% 100%;
}
.djyl{
  background: url("../../assets/icons/djyl.png") no-repeat;
  background-size: 100% 100%;
}
.qyfw{
  background: url("../../assets/icons/qyfw.png") no-repeat;
  background-size: 100% 100%;
}
.tzhh{
  background: url("../../assets/icons/tzhh.png") no-repeat;
  background-size: 100% 100%;
}

.hhzt{
  background: url("../../assets/icons/hhzt.png") no-repeat;
  background-size: 100% 100%;
}

.bg1{
  background: linear-gradient(0deg,#0158ee,#4589FF);
  box-shadow: 0 4px 7px rgba(7,84,219,.19);
}
.bgall{
  position: absolute;
  width: 1200px;
  top:10px;
  left: 50%;
  margin-left: -600px;
}
.p-index{
  width: 100%;
  height: 100vh;
  min-height: 850px;
  min-width: 1200px;
  position: relative;
  background-size: 100% 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  overflow: hidden;
}
.bg{
  background-size: cover;
  &:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:rgba(0,111,240,0.7);
  }
}
.tops{
  width: 800px;
  margin: 140px auto 0 auto;
  text-align: center;
  color: #fff;
  .title{
    width: 330px;
    margin: 10px auto;
  }
  .title1{
    width: 526px;
    height: 76px;
    margin: 10px auto;
  }
  .h2{
    font-size: 22px;
    font-weight: 500;
    margin: 20px 0;
  }
  .input-with-select{
    width: 650px;
    margin-top: 80px;
  }
  .btn{
    color: #fff;
    background-color: #409EFF;
    margin: 0px -20px;
  }
}

.content{
  width:1200px;
  margin: 0px auto;
  padding: 50px 0;
  .left1{
    width: 210px;
    height: 280px;
    background: linear-gradient(45deg,#0B83F1,#1C58CD);
    border-radius: 8px;
    padding: 20px;
    position: relative;
    color: #fff;
    font-size: 14px;
    .imgbg{
      position: absolute;
      width: 204px;
      height: 204px;
      bottom: 0;
      right: 0;
      background: url("../../assets/home/img.png") no-repeat;
      background-size: 100% 100%;

    }
    &:hover {
      background: linear-gradient(45deg,#35B1F5,#1C73DA);
    }
    .ltitle{
      font-size: 20px;
      font-weight: bolder;
      margin-bottom: 10px;
    }
    .chakan{
      margin-top: 40px;
    }
    .tub{
      text-align: center;
      padding-top: 35px;
    }
    i{
      display: inline-block;
      width: 55px;
      height: 60px;
    }
    .title{
      font-size:18px;
      font-weight: 550;
      color: #1b5ebc;
      text-align: center;
      padding: 20px;
    }
  }
  .left2{
    color: #fff;
    i{
      display: inline-block;
      width:30px;
      height:30px;
      margin-top: 14px;
    }
    .modes{
      margin-top: 10px;
      margin-left: 15px;
      width: 105px;
      height: 89px;
      background: linear-gradient(0deg,#006FF0,#00A8FF);
      box-shadow: 0 4px 7px rgba(7,84,219,.19);
      border-radius: 8px;
      float: left;
      text-align: center;
      cursor: pointer;
    }
    .modes:hover{
      background: linear-gradient(0deg,#C7A778,#FCE29A );
      .djyl{
        background: url("../../assets/icons/djyl1.png") no-repeat;
        background-size: 100% 100%;
      }
      .qyfw{
        background: url("../../assets/icons/qyfw1.png") no-repeat;
        background-size: 100% 100%;
      }
      .tzhh{
        background: url("../../assets/icons/tzhh1.png") no-repeat;
        background-size: 100% 100%;
      }
      .hhzt{
        background: url("../../assets/icons/hhzt1.png") no-repeat;
        background-size: 100% 100%;
      }
    }

    .title{
      font-size:16px;
      font-weight: 500;
      text-align: center;
      padding: 7px 0;
    }
  }
  .newsbox{
    width: 354px;
    height: 280px;
    background: rgba(255,255,255,0.5);
    border-radius: 8px;
    padding: 20px;
    margin-left: 8px;
    color: #333;
    overflow: hidden;
    .zxbg{
      display: inline-block;
      width: 96px;
      height: 24px;
      background-size: cover;
      margin-bottom: 10px;
      font-weight: bolder;
      font-size: 16px;
    }
    .seeMore {
        float: right;
        cursor: pointer;
        &:hover {
          color:#0079E9;
        }
    }

    .news-title{
      border-bottom: 1px dashed rgba(255,255,255,.3);
      padding-left: 5px;
      cursor: pointer;
      .title{
        width: 350px;
        padding:8px 0;
      }
      &:hover {
        color:#0079E9;
      }
    }
  }
}

</style>
